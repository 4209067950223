var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"container-main-content-title mb-5"},[_c('v-img',{attrs:{"max-width":"64","lazy-src":_vm.icon,"src":_vm.icon}}),_c('h1',{staticClass:"main-content-title"},[_vm._v(" "+_vm._s(_vm.$tc(_vm.menu, _vm.numberTranslate))+" ")])],1),_c('v-row',{staticClass:"mt-3"},_vm._l((_vm.subitems),function(item,index){return _c('v-col',{key:`subItem-${index}`,class:{
        'pa-1': true
      },attrs:{"cols":"6","sm":"4","md":"3","lg":"2"}},[_c('v-card',{class:'listcard elevation-1',attrs:{"to":item.url}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-center text-uppercase font-weight-black",attrs:{"cols":"12"}},[(item.icon)?_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.smAndDown ? 40 : 60},domProps:{"textContent":_vm._s(item.icon)}}):_c('v-img',{staticClass:"mx-auto",attrs:{"src":item.img,"height":"60","width":"60"}})],1),_c('v-col',{class:{
                'text-truncate': true,
                'font-weight-black': true,
                'text-center': true,
                'text-uppercase': true,
                caption: _vm.$vuetify.breakpoint.smAndDown
              },attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$tc(item.title, item.number))+" ")])],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }