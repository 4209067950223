<template>
  <v-container fluid>
    <div class="container-main-content-title mb-5">
      <v-img max-width="64" :lazy-src="icon" :src="icon"></v-img>
      <h1 class="main-content-title">
        {{ $tc(menu, numberTranslate) }}
      </h1>
    </div>
    <v-row class="mt-3">
      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="2"
        :class="{
          'pa-1': true
        }"
        v-for="(item, index) in subitems"
        :key="`subItem-${index}`"
      >
        <v-card :class="'listcard elevation-1'" :to="item.url">
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                class="text-center text-uppercase font-weight-black"
              >
                <v-icon
                  v-if="item.icon"
                  :size="$vuetify.breakpoint.smAndDown ? 40 : 60"
                  v-text="item.icon"
                ></v-icon>
                <v-img
                  v-else
                  class="mx-auto"
                  :src="item.img"
                  height="60"
                  width="60"
                ></v-img>
                <!-- :style="
                $vuetify.theme.dark
                  ? 'filter: invert(1)'
                  : 'filter: invert(47%) sepia(14%) saturate(0%) hue-rotate(151deg) brightness(96%) contrast(88%);'"-->
              </v-col>
              <v-col
                cols="12"
                :class="{
                  'text-truncate': true,
                  'font-weight-black': true,
                  'text-center': true,
                  'text-uppercase': true,
                  caption: $vuetify.breakpoint.smAndDown
                }"
              >
                {{ $tc(item.title, item.number) }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      numberTranslate: 1,
      icon: null,
      subitems: [],
      menu: this.$route.params.menu
    }
  },
  watch: {
    '$route.params.menu': {
      handler(val) {
        this.menu = val
        this.getMenu()
      }
    }
  },
  created() {
    this.getMenu()
  },
  methods: {
    getMenu() {
      let menu = this.$store.getters['navigation/getMenu'].find(
        (m) => m.title === this.menu
      )
      if (menu !== undefined && menu.sub !== undefined) {
        let submenu = menu.sub.filter((o) => o.show === true)
        if (submenu.length != 0) {
          this.icon = menu.icon
          this.numberTranslate = menu.number
          this.subitems = submenu
        } else {
          this.$router.push({ name: 'index' })
        }
      } else {
        this.$router.push({ name: 'index' })
      }
    }
  }
}
</script>
<style lang="sass">
.container
  &-main-content-title
    display: flex
    justify-content: center
    align-items: center
    gap: 10px
    .main-content-title
      color: var(--v-main-base)
      margin-bottom: 0
      font-weight: bold
</style>
